import React from "react"
import { Link, graphql} from "gatsby"
import Layout from "../components/layout"
import "./pages.scss"
import BackArrow from "../images/icons/arrow-left.png"
import SEO from "../components/seo";
  
const Logo = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png";

const SiteMap = ({ data, location }) => {

    let menuItems = [];
    if (data && data.site && data.site.siteMetadata && data.site.siteMetadata.menuItems) {
      menuItems = data.site.siteMetadata.menuItems;
    }
   
    const ageData = data.cms.ageStages.nodes
    const contextData = data.cms.contexts.nodes
    const domainData = data.cms.domains.nodes

    const domainMap = {};
    const parentDomains = {};

    domainData.forEach((domain) => {
        if (!domain.parentDatabaseId) {
        parentDomains[`id_${domain.databaseId}`] = domain.slug;
        }
    });

    domainData.forEach((domain) => {
        if (domain.parentDatabaseId) {
        if (!domainMap[parentDomains[`id_${domain.parentDatabaseId}`]]) {
            domainMap[parentDomains[`id_${domain.parentDatabaseId}`]] = [];
        }
        domainMap[parentDomains[`id_${domain.parentDatabaseId}`]].push(domain.slug);
        }
    });

    const renderSubDes = (data, slug, category) => {
        if (!data) {
          return;
        }
        return (
            <div className="site-map-item">
                <div className="pw-menu-item">
                    {category == "age_stages" ? "Ages & Stages" : category == "context" ? "Context" : category == "domain" ? "Domain" : ""}
                </div>
                {slug == "super-honestly" ?
                    <div className="site-map-sub-options">
                        <Link to={`/${slug}?age_stages=pre-parenting`} className="pw-menu-item">
                        Pre-parenting
                        </Link>
                    </div>
                : ''}
                {data.map(item => {
                    if (category == 'domain' && item.parentDatabaseId !== null) {
                        return null;
                    }

                    let link = `/${slug}?${category}=${item.slug}`;

                    if (category == 'domain' && domainMap[item.slug] && domainMap[item.slug].length) {
                        domainMap[item.slug].forEach(child => link += `&${category}=${child}`)
                    }

                    return (
                        <div className="site-map-sub-options">
                            {(category == "domain" && item.parentDatabaseId == null) || (category == "age_stages") || (category == "context") ?
                                <Link key={item.name} to={link} className="pw-menu-item">
                                    {item.name} <br />
                                    <em className="site-map-sub-options-1">{item.description}</em>
                                </Link>
                            : ""}
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderSubMenu = (data) => {
        if (!data) {
          return;
        }
        return (
            <div className="site-menu">
                {data.map(option => (
                <>
                    <div key={option.name} className="site-map-item">
                        <Link key={option.name} to={option.link} className="pw-menu-item">{option.name}</Link>
                    </div>
                    {option.subOptions && option.subOptions.map(subOption => (
                        <div className="site-map-sub-options">
                            <Link key={subOption.name} to={subOption.link} className="pw-menu-item">{subOption.name}</Link><br />
                        </div>
                    ))}
                </>
                ))}
            </div>
        )
    }

    const aboutUsData = (
        <div className="site-map-margin">
            {menuItems && menuItems.map(item => {
            if (item && item.slug === 'about-us') {
                return (
                    <div className="site-map-title">
                        <Link key={item.name} to="/about-us" className="pw-menu-item">{item.name}</Link>
                        {renderSubMenu(item.options)}
                    </div>
                )
            }})}
        </div>
    )

    const wiseJourneysData = (
        <div className="site-map-margin">
            {menuItems && menuItems.map(item => {
            if (item && item.slug === 'wise-journey') {
                return (
                    <div className="site-map-title">
                        <Link key={item.name} to="/wise-journey" className="pw-menu-item">{item.name}</Link> <br />
                        <Link key={item.name} to="/wise-journey" className="pw-menu-item site-map-item">
                            Discover a Journey
                        </Link>
                    </div>
                )
            }})}
        </div>
    )
    
    const wiseTipsData = (
        <div className="site-map-margin">
            <div className="site-map-title">
                <Link to="/wise-tips" className="pw-menu-item">WiseTips</Link>
                <div className="site-menu">
                    {renderSubDes(ageData, "wise-tips", "age_stages")}
                    {renderSubDes(domainData, "wise-tips", "domain")}
                    {renderSubDes(contextData, "wise-tips", "context")}
                </div>
            </div>
        </div>
    )

    const superHonestlyData = (
        <div className="site-map-margin">
            <div className="site-map-title">
                <Link to="/super-honestly" className="pw-menu-item">SuperHonestly </Link>
                <div className="site-menu">
                    {renderSubDes(ageData, "super-honestly", "age_stages")}
                </div>
            </div>
        </div>
    )

    return (
        <div className="site-map-background">
            <Layout>
                <SEO
                    title="Sitemap"
                    path={location.pathname}
                />
                <img loading="lazy" className="super-ho-logo w3-hide-small" src={Logo} />
                <div className="w3-row back-arrow">
                    <Link to="/" className="pw-wt-detail-back-to back-text">
                        <img src={BackArrow} className="pw-wt-detail-back-icon" />
                        <div>
                            Back to Homepage
                        </div>
                    </Link>
                </div>
                <div className="w3-white" style={{paddingTop: "25px"}}>
                    <div className="site-map-header w3-center">SITEMAP</div>
                    <div className="site-map-space">
                        <div className="w3-hide-small w3-content site-map-main">
                            {aboutUsData}
                            {wiseJourneysData}
                            {wiseTipsData}
                            {superHonestlyData}
                        </div>
                    </div>
                    <div className="w3-hide-medium w3-hide-large w3-content site-map-main">
                        {aboutUsData}
                        {wiseJourneysData}
                        {superHonestlyData}
                        {wiseTipsData}
                    </div>
                </div>
            </Layout>
        </div> 
    )
}

export const query = graphql`
  {
    cms {
      ageStages(first: 100) {
        nodes {
          slug
          name
          link
          description
        }
      }
      domains(first: 100) {
        nodes {
          name
          link
          slug
          parentDatabaseId
          databaseId
        }
      }
      contexts(first: 100) {
        nodes {
          link
          name
          slug
        }
      }
    }
    site {
      siteMetadata {
        menuItems {
          name
          type
          slug
          mobileViewOrder
          link
          options {
            link
            name
            type
            subOptions {
              link
              name
              type
            }
          }
        }
      }
    }
  }
`

export default SiteMap;